<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Whatsapp Integration List</h3>
        <el-row :gutter="12" class="integration-button-container">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-button type="info" round @click="integrations()"
              >Add New Integrations</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="12">
        <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
          class="table-container"
        >
          <br /><br />
          <template>
            <el-table border style="margin: auto; width: 95%">
              <el-table-column prop="accountName" label="Account Name" />
              <el-table-column prop="PhoneNumber" label="Phone Number" />
            </el-table>
            <center>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="paginationProps"
                :page-size="pageSize"
                :pager-count="5"
                layout="prev, pager, next, jumper,sizes, total"
                small
              >
              </el-pagination>
              <div style="margin-top: 10px">Total</div>
            </center>
          </template>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhatsappIntegrationList",
  components: {},
  data() {
    //added
    return {
      loading: false,
    };
  },
  methods: {
    async integrations() {
      this.$router.push({ path: "/configuration/whatsapp" });
    },
  },
};
</script>
<style lang="scss" scoped>
.integration-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
